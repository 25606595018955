/** @define NotificationBanner */
.NotificationBanner
{
  background-color: $brand-darkgreen;
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 20px;

  @include bpgte(sm)
  {
    margin-bottom: 40px;
  }

  &-text
  {
    font-family: $font-family-secondary;
    color: white;
  }
}
