.slick
{
  width: 100%;

  &-wrapper
  {
    width: 100%;
  }

  &-slider
  {
    &.slick-slider
    {
      margin-bottom: 16px;
    }
  }

  &-list,
  &-track
  {
    height: 100%;
    display: flex;
  }

  &-slide
  {
    height: auto;
    flex: 1 0 auto;
  }

  &-dots
  {
    bottom: 0;
    position: relative;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    li
    {
      margin: 0 2px;
      height: 8px;
      width: 8px;

      button
      {
        padding: 0;
        width: 8px;
        height: 8px;
        background: $gray-6;
        border: 1px solid $brand-primary;
        border-radius: 4px;
      }

      button::before
      {
        content: '';
        display: none;
      }

      &.slick-active button
      {
        background: $brand-primary;
      }
    }
  }

  &-playPause
  {
    margin: 0 2px;
    height: 16px;
    width: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after
    {
      width: 16px;
      height: 16px;
      color: $brand-primary;
      line-height: normal;
    }

    @include fa-icon-after($fa-var-play);

    &.is-playing
    {
      @include fa-icon-after($fa-var-pause);
    }
  }
}
