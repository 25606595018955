/** @define Link */

@mixin Link
{
  color: $brand-tertiary;
  cursor: pointer;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    text-decoration: underline;
  }
}

@mixin Link--primary
{
  /* postcss-bem-linter: ignore */
  &::after
  {
    content: url(assets/images/green_angle_right.svg);
    margin-left: 5px;
  }
}

@mixin Link--primarySmall
{
  /* postcss-bem-linter: ignore */
  &::after
  {
    content: url(assets/images/green_angle_right_small.svg);
    margin-left: 5px;
  }
}

@mixin Link--header
{
  color: $brand-darkgreen;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    color: $brand-tertiary;

    /* postcss-bem-linter: ignore */
    &::before
    {
      background-image: url(assets/images/header-pinHover.svg);
    }
  }

  @include bplte(xs)
  {
    display: none;
  }

  @include font-sizes(16px, 24px);
}

@mixin Link--headerSecondary
{
  @include font-sizes(18px, 22px);
  color: $brand-link;
  display: flex;

  @include bplte(xs)
  {
    display: none;
  }

  /* postcss-bem-linter: ignore */
  &:hover
  {
    text-decoration: underline;
  }

  /* postcss-bem-linter: ignore */
  &::after
  {
    content: "";
    display: block;
    width: 17px;
    height: 24px;
    background-image: url(assets/images/chevron.svg);
  }
}

@mixin Link--breadcrumbs
{
  font-size: 12px;
  color: $brand-link;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    text-decoration: underline;
  }

  /* postcss-bem-linter: ignore */
  &:active
  {
    color: $gray-1;
  }
}

@mixin Link--body
{
  @include font-sizes(16px, 22px);
  color: $brand-link;
  text-decoration: underline;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    text-decoration: none;
  }
}

.Link
{
  @include Link;

  &--primary
  {
    @include Link--primary;
  }

  &--primarySmall
  {
    @include Link--primarySmall;
  }

  &--header
  {
    @include Link--header;
  }

  &--headerSecondary
  {
    @include Link--headerSecondary;
  }

  &--breadcrumbs
  {
    @include Link--breadcrumbs;
  }

  &--body
  {
    @include Link--body;
  }
}
