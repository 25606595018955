/** @define Event */

.Event
{
  @include l-container;
  display: flex;
  background-color: $brand-lightblue;
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 32px;

  @include bplte(xs)
  {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px;
  }

  &-photoWrapper
  {
    display: flex;
    justify-content: center;
  }

  &-heading
  {
    @include font-sizes(27px, 35px);
    color: $brand-lightgreen;
    padding-bottom: 24px;

    @include bplte(xs)
    {
      padding-bottom: 16px;
    }
  }

  &-info
  {
    display: flex;
    background-color: $brand-white;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include bplte(xs)
    {
      padding: 16px;
      align-items: flex-start;
      text-align: left;
    }
  }

  &-body
  {
    @include html-styles;

    /* postcss-bem-linter: ignore */
    p:not(:last-child)
    {
      padding-bottom: 16px;
    }
  }

  &-ctaWrapper
  {
    padding-top: 24px;

    @include bplte(xs)
    {
      padding-top: 16px;
    }
  }

  &-cta
  {
    @include font-sizes(16px, 24px);
    width: 133px;
    padding: 12px 20px;
    white-space: nowrap;
  }
}
