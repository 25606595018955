/** @define PromoCarousel */
.PromoCarousel
{
  @include l-container;
  position: relative;
  background-size: cover;

  @include bpgte(sm)
  {
    margin-top: 40px;
  }
}
