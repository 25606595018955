/** @define Button */

.Button
{
  appearance: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;

  &-primary
  {
    @include font-sizes(20px, 20px);
    font-weight: $font-weight-bold;
    background-color: $brand-secondary;
    color: white;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-around;

    &:hover
    {
      background-color: $brand-darkorange;
    }

    &:focus,
    &:active
    {
      color: $brand-link;
    }
  }

  &:link,
  &:visited,
  &:active,
  &:hover,
  &:focus
  {
    text-decoration: none;
  }

  &--clear
  {
    background-color: transparent;
    border: none;
  }

  &--cta
  {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: white;

    &:focus
    {
      outline: none;
    }

    &:hover,
    &:focus
    {
      background-color: white;
      border-color: $brand-primary;
      color: $brand-primary;
    }
  }

  &--inverted
  {
    background-color: transparent;
    border-color: white;
    color: white;

    &:focus
    {
      outline: none;
    }

    &:hover,
    &:focus
    {
      background-color: white;
      border-color: $brand-primary;
      color: $brand-primary;
    }
  }

  &--invertedBrand
  {
    background-color: white;
    border-color: $brand-primary;
    color: $brand-primary;

    &:focus
    {
      outline: none;
    }

    &:hover,
    &:focus
    {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: white;
    }
  }

  &--tight
  {
    padding-top: 1/3 * 1em;
    padding-bottom: 1/3 * 1em;
    padding-left: 2/3 * 1em;
    padding-right: 2/3 * 1em;
  }

  &--loose
  {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
  }
}
