/** @define Main */

.Main
{
  width: 100%;

  @include bpgte(sm)
  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;

    &-breadcrumbs
    {
      order: -99;

      &--mobile
      {
        @include bpgte(sm)
        {
          display: none;
        }
      }
    }
  }

  &-breadcrumbs
  {
    @include bplte(xs)
    {
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 20px;
    }
  }

  &-content
  {
    width: 100%;
  }
}
