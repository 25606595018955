/** @define Heading */

@mixin Heading
{
  font-family: $font-family-secondary;
}

@mixin Heading--major
{
  @include font-sizes(35px, 35px, 28px, 28px);
  color: white;
  font-weight: $font-weight-bold;
}

@mixin Heading--minor
{
  @include font-sizes(27px, 36px);
  color: white;
}

@mixin Heading--title
{
  @include font-sizes(22px, 26px);

  font-family: $font-family-main;
}

@mixin Heading--sub
{
  @include font-sizes(18px, 18px);
  color: white;
  font-weight: $font-weight-bold;
}

@mixin Heading--subWithIcon($image)
{
  color: $brand-lightgreen;
  font-size: 18px;
  display: flex;

  /* postcss-bem-linter: ignore */
  &::before
  {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url($image);
    background-size: cover;
    margin-right: 8px;
  }
}

@mixin Heading--alpha
{
  @include font-sizes(27px, 36px);
}

.Heading
{
  @include Heading;

  &--alpha
  {
    @include Heading--alpha;
  }

  &--major
  {
    @include Heading--major;
  }

  &--minor
  {
    @include Heading--minor;
  }

  &--sub
  {
    @include Heading--sub;
  }

  &--title
  {
    @include Heading--title;
  }
}
