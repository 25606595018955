/** @define CollapsingBanner */

.CollapsingBanner
{
  @include html-styles;

  background-color: $collapsing-banner-background;
  color: $collapsing-banner-fontcolor;
  padding: $padded-padding 0;

  &-container
  {
    @include l-container;

    @include bpgte(sm)
    {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-content
  {
    font-size: 14px;
    line-height: 20px;
  }

  &-cta,
  &-heading
  {
    font-size: 16px;
    line-height: 24px;
  }

  &-left
  {
    @include bpgte(sm)
    {
      width: percentage(2/3);
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  &-right
  {
    @include bpgte(sm)
    {
      width: percentage(1/3);
      flex-shrink: 1;
      display: flex;
    }
  }

  &-right &-content
  {
    @include bpgte(sm)
    {
      margin: auto;
    }
  }

  &--expanded &-toggle--collapse
  {
    display: inline;
  }

  &-toggle
  {
    background: transparent;
  }

  &--expanded &-content--full
  {
    display: block;
  }

  &-cta
  {
    font-weight: $font-weight-bold;

    @include bpgte(sm)
    {
      border-radius: 4px;
      padding: $padded-padding/2 $padded-padding;
    }
  }

  &-ctaWrapper
  {
    margin-top: $spaced-margin;

    @include bpgte(sm)
    {
      margin-top: 0;
    }
  }

  &-heading
  {
    font-weight: $font-weight-bold;
  }
}