/** @define Header */

.Header
{
  height: $header-height-mobile;
  display: flex;
  width: 100%;

  @include bpgte(md)
  {
    height: $header-height-desktop;
  }

  &-main
  {
    display: flex;
    padding: 20px;

    @include bpgte(md)
    {
      padding: 40px;
    }
  }

  &-divider
  {
    margin-left: 20px;
    margin-right: 20px;
    border-right: 1px solid $brand-primary;
  }

  &-logoLink
  {
    display: flex;
  }

  &-logoImage
  {
    height: 30px;
    object-fit: contain;

    @include bpgte(sm)
    {
      height: 36px;
    }
  }

  &-link1Wrapper
  {
    @include bplte(xs)
    {
      display: none;
    }

    @include bpgte(sm)
    {
      width: 359px;
      height: 92px;
      background-image: url(assets/images/accent.svg);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-bottomRow
  {
    @include bpgte(sm)
    {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include l-container;
    }

    @include bplte(xs)
    {
      display: none;
    }
  }

  &-mapPin,
  &-officer
  {
    @include bpgte(sm)
    {
      display: none;
    }
  }
}
