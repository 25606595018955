.ArticlesNew
{
  color: $gray-1;
  font-family: $font-family-secondary;

  &-container
  {
    @include l-container;
    padding-top: 40px;
    padding-bottom: 0;

    @include bplte(sm)
    {
      padding-top: 32px;
      padding-bottom: 0;
    }
  }

  &-cardsWrapper
  {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding: 48px 0;

    @include bplte(sm)
    {
      grid-template-columns: repeat(1, 1fr);
      padding: 32px 0;
    }
  }

  &-card
  {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $brand-white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 3px 10px -4px $brand-black;
    transition: all 0.3s ease;
    padding: 16px;
  }

  &-card:hover
  {
    transform: translateY(-5px);
    box-shadow: 0 3px 10px -4px $brand-black;
  }

  &-heading
  {
    @include font-sizes(32px, 40px);
    font-weight: $font-weight-medium;
    color: $brand-primary;
  }

  &-image
  {
    width: 100%;
    height: 218px;
    object-fit: cover;
  }

  &-content
  {
    margin-top: 24px;
  }

  &-title
  {
    @include font-sizes(23px, 31px);
    font-weight: $font-weight-medium;
    color: $gray-1;
  }

  &-description
  {
    @include font-sizes(16px);
    display: none;
  }

  &-date
  {
    @include font-sizes(16px, 26px);
    font-weight: $font-weight-normal;
    margin-top: 8px;
  }

  &-link
  {
    display: none;
    text-decoration: none;
    font-weight: $font-weight-bold;
    transition: all 0.3s ease;
  }
}
