@import "../common/variables";
@import "./Spacing.scss";

$generate-classes: false !default;
$col-gap: spacing(base) !default;
$splitting-points: (sm, md, lg) !default;

$names: (
  1: one,
  2: two,
  3: three,
  4: four,
) !default;

$grids: (
  half: 2,
  thirds: 3,
  fourths: 4,
) !default;

@mixin l-Split($bp, $gap, $parentclass: &)
{
  @at-root
  {
    @include bpgte($bp)
    {
      #{$parentclass}--#{$bp}
      {
        @include margin(-$gap, (left, right));

        display: flex;

        & > *
        {
          @include padding($gap, (left, right));
        }
      }

      @each $name, $denominator in $grids
      {
        @for $i from 1 through $denominator
        {
          $childName: map-get($names, $i);
          $combined: $parentclass + '--' + $bp + $parentclass + '--' + $name;

          #{$combined} > #{$parentclass}-#{$childName}
          {
            width: percentage($i/$denominator);
          }
        }
      }
    }
  }
}

@mixin l-columns($count, $gap-width: 0)
{
  columns: $count;
  column-gap: $gap-width;

  & > *
  {
    page-break-inside: avoid;
  }
}

@mixin l-centeredColumn
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

// TODO: Are we still using this stuff?
// For all columns of the $class type (xs,sm,md,lg), when combined with $selector, set attributes to @content
@mixin all-columns-with-selector($class, $selector)
{
    $list: '';
    $i: 1;
    $list: ".col-#{$class}-#{$i}.#{$selector}";
    @for $i from 2 through $grid-columns {
      $list: ".col-#{$class}-#{$i}.#{$selector}, #{$list}";
    }
    #{$list} {
      @content;
    }
}

@if $generate-classes
{
  // Add top and bottom margin to columns when they wrap due to screen width
  @media(max-width:$screen-xs-max)
  {
    @include all-columns-with-selector("sm", "col-spaced")
    {
      margin-top:$spaced-margin;
      margin-bottom:$spaced-margin;
    }

    @include all-columns-with-selector("sm", "col-spaced-top")
    {
      margin-top:$spaced-margin;
    }

    @include all-columns-with-selector("sm", "col-spaced-bottom")
    {
      margin-bottom:$spaced-margin;
    }
  }

  @media(max-width:$screen-sm-max)
  {
    @include all-columns-with-selector("md", "col-spaced")
    {
      margin-top:$spaced-margin;
      margin-bottom:$spaced-margin;
    }

    @include all-columns-with-selector("md", "col-spaced-top")
    {
      margin-top:$spaced-margin;
    }

    @include all-columns-with-selector("md", "col-spaced-bottom")
    {
      margin-bottom:$spaced-margin;
    }
  }

  @media(max-width:$screen-md-max)
  {
    @include all-columns-with-selector("lg", "col-spaced")
    {
      margin-top:$spaced-margin;
      margin-bottom:$spaced-margin;
    }

    @include all-columns-with-selector("lg", "col-spaced-top")
    {
      margin-top:$spaced-margin;
    }

    @include all-columns-with-selector("lg", "col-spaced-bottom")
    {
      margin-bottom:$spaced-margin;
    }
  }

  // Add padding that disappears on wrap
  @media(min-width:$screen-sm-min)
  {
    @include all-columns-with-selector("sm", "col-padded")
    {
      padding:$padded-padding;
    }
  }

  @media(min-width:$screen-md-min)
  {
    @include all-columns-with-selector("md", "col-padded")
    {
      padding:$padded-padding;
    }
  }

  @media(min-width:$screen-lg-min)
  {
    @include all-columns-with-selector("lg", "col-padded")
    {
      padding:$padded-padding;
    }
  }

  .row
  {
    &-separator
    {
      border-bottom:solid 1px #222;
    }

    &-vertical-margin
    {
      &-top
      {
        margin-top: $vertical-margin-top;

        &-sm
        {
          margin-top: $vertical-margin-top / 2;
        }
      }

      &-bottom
      {
        margin-bottom: $vertical-margin-top;

        &-sm
        {
          margin-bottom: $vertical-margin-top / 2;
        }
      }

      &-sm
      {
        @extend .row-vertical-margin-top-sm;
        @extend .row-vertical-margin-bottom-sm;
      }

      @extend .row-vertical-margin-top;
      @extend .row-vertical-margin-bottom;
    }
  }

  @each $abbreviation, $selector, $breakPixels in (lg, "min-width", $screen-lg-min),
                                                  (md, "min-width", $screen-md-min),
                                                  (sm, "min-width", $screen-sm-min),
                                                  (xs, "max-width", $screen-xs-max)
  {
    @media(#{$selector}: #{$breakPixels})
    {
      .row-flex-#{$abbreviation}
      {
        display: flex;
        flex-pack: distribute;
        flex-direction:row;
      }
      .col-flex-#{$abbreviation}
      {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .col-flex-child
        {
          flex-grow: 1;
        }
      }
    }
  }

  .l-row
  {
    @include l-row;
  }

  @for $colCount from 1 through $grid-columns
  {
    @each $start, $end, $direction in (lg, null, "up"),
                                      (md, null, "up"),
                                      (sm, null, "up"),
                                      (null, md, "down"),
                                      (null, sm, "down"),
                                      (null, xs, "down")
    {
      $abbreviation: if($start, $start, $end);

      .l-col-#{$abbreviation}-#{$colCount}-#{$direction}
      {
        @include bp($start, $end)
        {
          @include l-col($colCount, $grid-columns)
        }
      }
    }
  }

  @for $colCount from 1 through $grid-columns
  {
    @each $start, $end in (lg, null),
                          (md, md),
                          (sm, sm),
                          (null, xs)
    {
      $abbreviation: if($start, $start, $end);

      .l-col-#{$abbreviation}-#{$colCount}
      {
        @include bp($start, $end)
        {
          @include l-col($colCount, $grid-columns)
        }
      }
    }
  }

  @each $bp in $splitting-points
  {
    @include l-Split($bp, $col-gap, '.l-Split');
  }

  .l-centeredColumn
  {
    @include l-centeredColumn;
  }
}

@mixin hidden-xs
{
  @include bplte(xs)
  {
    display: none;
  }
}

@mixin hidden-sm
{
  @include bp(sm, sm)
  {
    display: none;
  }
}

@mixin hidden-md
{
  @include bp(md, md)
  {
    display: none;
  }
}

@mixin hidden-lg
{
  @include bpgte(lg)
  {
    display: none;
  }
}

@mixin visible-only-xs
{
  @include bpgte(sm)
  {
    display: none;
  }
}

.l-container
{
  @include l-container;
}

.l-visible-only-xs
{
  @include visible-only-xs;
}

.l-hidden-xs
{
  @include hidden-xs;
}

.l-hidden-sm
{
  @include hidden-sm;
}

.l-hidden-md
{
  @include hidden-md;
}

.l-hidden-lg
{
  @include hidden-lg;
}
