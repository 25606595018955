// common theming goes here, e.g. global component styling

body
{
  @include anti-aliased;
}

.c-bread-crumbs-item::before
{
  display: none;
}

/* postcss-bem-linter: ignore */
.c-bread-crumbs
{
  /* postcss-bem-linter: ignore */
  &-list
  {
    display: flex;

    @include bplte(xs)
    {
      flex-wrap: wrap;
    }
  }

  /* postcss-bem-linter: ignore */
  &-item
  {
    display: flex;
    align-items: center;

    /* postcss-bem-linter: ignore */
    &:not(:last-child)::after
    {
      content: "";
      display: block;
      width: 21px;
      height: 20px;
      background-image: url(assets/images/angle.svg);
    }

    /* postcss-bem-linter: ignore */
    &:last-child
    {
      font-size: 12px;
      color: $gray-1;
    }
  }

  a
  {
    @include Link--breadcrumbs;
  }
}
