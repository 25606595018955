/** @define LearnCenter */
.LearnCenter
{
  @include l-container;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $brand-lightblue;
  padding: $spacing-MLO*2;
  display: flex;
  flex-direction: column;

  @include bpgte(sm)
  {
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: row;
    padding: $spacing-MLO*4;
  }

  &-leftCol,
  &-rightCol
  {
    @include l-col(6, 12);

    @include bplte(xs)
    {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    @include bpgte(sm)
    {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-leftCol
  {
    padding: $spacing-MLO*2;
    background-color: white;
    width: 100%;
  }

  &-rightCol
  {
    width: 100%;
    position: relative;
    margin-top: $spacing-MLO*2;
    display: flex;
    flex-direction: column;
  }

  &-title
  {
    color: $brand-lightgreen;
    margin-bottom: 20px;

    @include bpgte(sm)
    {
      margin-bottom: 40px;
    }
  }

  &-text
  {
    font-family: $font-family-main;
    margin-bottom: $spacing-MLO*2;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-MLO*4;
    }
  }

  &-buttonWrapper
  {
    display: flex;
    padding-bottom: 20px;
  }

  &-image
  {
    width: 100%;
    height: 162px;
    object-fit: cover;

    @include bpgte(sm)
    {
      position: absolute;
      height: 100%;
    }
  }

  /* postcss-bem-linter: ignore */
  .youtube-embed-video-wrapper
  {
    margin-bottom: 0;
  }

  /* postcss-bem-linter: ignore */
  .youtube-video-wrapper,
  .youtube-video-wrapper div
  {
    height: 100%;
  }
}
