//
// Phone Component SCSS Stylesheet
//

.c-phone
{
  &-label
  {

  }

  &-number
  {
    &-wrapper
    {

    }
    &-link
    {
      @include bpgte(sm)
      {
        display: none;
      }
    }

    &-span
    {
      display: none;

      &-nolink
      {
        display: inline;
      }

      @include bpgte(sm)
      {
        display: inline;
      }
    }
  }

  &-main
  {

  }
  &-alternate
  {

  }
  &-mobile
  {

  }
  &-fax
  {

  }
  &-tty
  {

  }
  &-toll-free
  {

  }
}
