// location level theming goes here
.slick-slider
{
  &.slick-slider
  {
    margin-bottom: 0;
  }
}

.slick-disabled
{
  opacity: 0.5;
}

.slick-slide
{
  margin: 0 27px;
}

.slick-list
{
  margin: 0 -27px;
}

.youtube
{
  &-prevArrow,
  &-nextArrow
  {
    color: $brand-primary;
    display: flex;
  }

  &-carousel
  {
    flex: 1;
  }

  &-buttonWrapper
  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 45px;
  }

  &-dots
  {
    li
    {
      width: 15px;
      height: 15px;

      button
      {
        width: 15px;
        height: 15px;
        border: none;
        border-radius: 8px;
      }
    }
  }

  &-video-title
  {
    font-size: 27px;
    color: $brand-primary;
    background: $brand-white;
    text-align: center;
    padding: 5px;
    margin-bottom: 16px;
    border: $border-green;
  }
}
