/** @define NearbyBranches */
.NearbyBranches
{
  @include l-container;
  margin-top: 20px;
  margin-bottom: 20px;

  @include bpgte(sm)
  {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include bplte(xs)
  {
    padding-left: $spacing-MLO*2;
    padding-right: $spacing-MLO*2;
  }

  &-title
  {
    color: $brand-lightgreen;
    margin-bottom: 20px;

    @include bpgte(sm)
    {
      margin-bottom: 40px;
    }
  }

  &-cardWrappers
  {
    display: flex;

    @include bplte(sm)
    {
      flex-wrap: wrap;
    }
  }

  &-buttonWrapper
  {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    @include bpgte(sm)
    {
      justify-content: space-around;
      margin-top: 40px;
    }
  }

  &-carouselWrapper
  {
    @include bplte(xs)
    {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  /* postcss-bem-linter: ignore */
  .slick-next::before,
  .slick-prev::before
  {
    color: $brand-primary;
  }

  /* postcss-bem-linter: ignore */
  .slick-track
  {
    height: 100%;

    /* postcss-bem-linter: ignore */
    & .LocationCard,
    & .locationCard:not(:last-child)
    {
      margin: 0 20px;

      /* postcss-bem-linter: ignore */
      &:focus
      {
        outline: none;
      }
    }

    /* postcss-bem-linter: ignore */
    & .locationCard:first-child
    {
      margin-left: 0;
    }

    /* postcss-bem-linter: ignore */
    & .locationCard:first-child
    {
      margin-right: 0;
    }
  }
}
