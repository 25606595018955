/** @define Text */

@mixin Text
{
  @include font-sizes(16px, 22px);

  font-family: $font-family-main;
  color: $gray-1;
}

@mixin Text--small($line-height: 14px)
{
  @include font-sizes(12px, $line-height);
}

@mixin Text--bold
{
  font-weight: $font-weight-bold;
}

.Text
{
  @include Text;
}
