/** @define Footer */

$footer-break-point-max: xs !default;
$footer-break-point-min: sm !default;

.Footer
{
  flex-shrink: 0;

  &-container
  {
    padding: 15px;
  }

  &-block:not(:last-child)
  {
    @include Text;
    @include Text--small;

    margin-bottom: 15px;
  }

  &-fdic
  {
    margin-left: 8px;
  }
}
