// This file contains styling mixins that are provided for convenience
// They are meant to be overridden or replaced

// Utility Mixins:

@import '../components/Util/UtilityMixins';

@mixin MLOStyles()
{
  .Header
  {
    @include l-container;

    @include bpgte(sm)
    {
      flex-wrap: wrap;
      height: 148px;
    }

    &-mobileIcon
    {
      @include bplte(xs)
      {
        width: 24px;
        height: 24px;
      }
    }

    &-officer,
    &-mapPin
    {
      @include bplte(xs)
      {
        display: flex;
        align-items: center;
      }
    }

    height: 50px;

    &-main
    {
      width: 100%;
      padding: 0;

      @include bpgte(sm)
      {
        justify-content: space-between;

        @include l-container;
      }

      @include bplte(xs)
      {
        justify-content: space-between;
        align-items: center;
        padding-left: 18px;
        padding-right: 18px;
      }
    }

    &-logoWrapper
    {
      @include bpgte(sm)
      {
        margin-top: 30px;
      }
    }
  }

  .Footer
  {
    &-container
    {
      padding-top: 32px;
      border-top: $border-footer;

      @include bpgte(sm)
      {
        @include l-container;
      }
    }

    &-link
    {
      text-decoration: underline;

      &:hover
      {
        text-decoration: none;
      }
    }
  }
}

@mixin html-styles
{
  i,
  em
  {
    font-style: italic;
  }

  b,
  strong
  {
    font-weight: $font-weight-bold;
  }

  a
  {
    text-decoration: underline;

    &:hover
    {
      text-decoration: none;
    }
  }

  ol
  {
    list-style-type: decimal;
  }

  ul
  {
    list-style: disc;
  }

  ol,
  ul
  {
    list-style-position: inside;
  }

  li
  {
    margin-left: 25px;
  }

  sup
  {
    vertical-align: super;
    font-size: 13px;
  }
}
