// Add any font families here

// Example:
// @font-face {
//   font-family: 'Helvetica Neue Bold Condensed';
//   src: url('helveticaneue-boldcond-webfont.eot');
//   src:
//     url('helveticaneue-boldcond-webfont.eot?#iefix') format("embedded-opentype"),
//     url('helveticaneue-boldcond-webfont.woff') format("woff"),
//     url('helveticaneue-boldcond-webfont.ttf') format("truetype"),
//     url('helveticaneue-boldcond-webfont.svg#helvetica_neueboldcondensed') format("svg");
//   font-weight: $font-weight-normal;
//   font-style: normal;
// }

// @font-face
// {
//   font-family: 'CitiSans';
//   src: url('fonts/citisa01-webfont.woff') format("woff");
//   font-weight: $font-weight-normal;
//   font-style: normal;
// }

@font-face
{
  font-family: 'CitiSans';
  src: url('fonts/citisa.woff') format("woff");
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face
{
  font-family: 'CitiSans';
  src: url('fonts/citisa-bold.woff') format("woff");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face
{
  font-family: 'CitiSans';
  src: url('fonts/citisa-italic.woff') format("woff");
  font-weight: $font-weight-normal;
  font-style: italic;
}

@font-face
{
  font-family: 'CitiSans';
  src: url('fonts/citisa-italicBold.woff') format("woff");
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face
{
  font-family: 'FiraSans';
  src: url('fonts/FiraSans-Regular.ttf') format("truetype");
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face
{
  font-family: 'FiraSans';
  src: url('fonts/FiraSans-SemiBold.ttf') format("truetype");
  font-weight: $font-weight-semibold;
  font-style: normal;
}

@font-face
{
  font-family: 'FiraSans';
  src: url('fonts/FiraSans-Light.ttf') format("truetype");
  font-weight: $font-weight-light;
  font-style: normal;
}
