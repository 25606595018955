@mixin l-container
{
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include bpgte(sm)
  {
    width: $container-tablet-base;
  }

  @include bpgte(md)
  {
    width: $container-desktop-base;
  }

  @include bpgte(lg)
  {
    width: $container-large-desktop-base;
  }
}
