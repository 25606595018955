/** @define ResultList */

.ResultList
{
  &-item
  {
    border-bottom: 1px solid $gray-3;
    padding: 16px;

    &:first-child
    {
      border-top: 1px solid $gray-3;
    }

    &:hover,
    &.is-hovered
    {
      @include bpgte(md)
      {
        background-color: #f7f7f7;
      }
    }

    &.is-selected
    {
      @include bpgte(md)
      {
        background-color: $gray-2;
      }
    }

    &--ordered
    {
      @include bpgte(md)
      {
        counter-increment: resultCount;
        position: relative;
        padding-left: 15px;

        &::before
        {
          content: counter(resultCount, decimal) '.';
          position: absolute;
          left: 15px;
          line-height: 36px;
        }
      }
    }
  }
}
