.ObjectFit
{
  object-fit: cover;

  &-container
  {
    display: flex;
    align-items: flex-start;
    background-size: cover;

    .ObjectFit
    {
      opacity: 0;
    }
  }
}
