/** @define AutoCard */

.AutoCard
{
  width: 100%;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 4px;

  &:first-child
  {
    padding-top: 8px;
  }

  &:last-child
  {
    padding-bottom: 8px;
  }

  &-wrapper
  {
    display: flex;
    flex-grow: 1;
  }

  &-imageWrapper
  {
    margin-right: 10px;
    display: flex;
    align-items: center;

    &--default
    {
      width: 50px;
      height: 50px;
      justify-content: center;
      background-color: $gray-2;

      /* postcss-bem-linter: ignore */
      .fa-user
      {
        font-size: 40px;
      }
    }
  }

  &-image
  {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 2px;
  }

  &-name
  {
    font-size: 18px;
    font-weight: $font-weight-bold;
  }

  &-id,
  &-address
  {
    margin-top: 5px;
  }

  &-hidden
  {
    display: none;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    font-style: italic;
    color: $gray-1;
  }
}

/* postcss-bem-linter: ignore */
.ui-state-active
{
  /* postcss-bem-linter: ignore */
  .AutoCard
  {
    /* postcss-bem-linter: ignore */
    &-hidden
    {
      @include bpgte(sm)
      {
        display: flex;
      }
    }
  }
}
