/** @define AutoItem */

.AutoItem
{
  width: 100%;

  &-wrapper
  {
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-grow: 1;
  }

  &-hidden
  {
    display: none;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    font-style: italic;
    color: $gray-1;
  }
}

/* postcss-bem-linter: ignore */
.ui-state-active
{
  /* postcss-bem-linter: ignore */
  .AutoItem
  {
    /* postcss-bem-linter: ignore */
    &-hidden
    {
      @include bpgte(sm)
      {
        display: flex;
      }
    }
  }
}

