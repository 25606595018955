/** @define Nap */
.Nap
{
  @include l-container;

  @include bpgte(md)
  {
    display: flex;
  }

  &-leftCol
  {
    width: 65.5%;

    @include bpgte(sm)
    {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }

    @include bplte(sm)
    {
      width: 100%;
    }

    @include bplte(xs)
    {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-rightCol
  {
    @include bpgte(sm)
    {
      padding-top: $spacing-MLO*4;
      padding-bottom: $spacing-MLO*4;
      padding-left: $spacing-MLO*6;
      padding-right: $spacing-MLO*6;
      background-color: $brand-lightblue;
      width: 35.5%;
    }

    @include bplte(sm)
    {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-advisorName
  {
    margin-bottom: $spacing-MLO*2;
    max-width: 392px;

    @include bplte(xs)
    {
      z-index: 2;
      position: relative;
    }
  }

  &-title
  {
    margin-bottom: $spacing-MLO*2;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-MLO*3;
    }

    @include bplte(xs)
    {
      z-index: 2;
      position: relative;
    }
  }

  &-nmls
  {
    margin-bottom: $spacing-MLO*2;
  }

  &-imageWrapper
  {
    overflow: hidden;

    @include bpgte(sm)
    {
      width: 39%;
      min-width: 288px;
    }

    @include bplte(xs)
    {
      height: 315px;
    }
  }

  &-image
  {
    // Doing positions and translations because IE doesn't support object-fit
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    max-height: none;
    max-width: none;
  }

  &-button
  {
    @include font-sizes(17.9px, 20px);
    width: 100%;

    @include bpgte(sm)
    {
      width: 242px;
    }
  }

  &-actNow
  {
    margin-bottom: $spacing-MLO*3;
  }

  &-phone
  {
    margin-bottom: $spacing-MLO*3;

    @include bpgte(sm)
    {
      margin-bottom: 42px;
    }
  }

  &-addressTitle,
  &-contactTitle,
  &-calculator
  {
    margin-bottom: $spacing-MLO*2;
  }

  &-addressTitle
  {
    @include Heading--subWithIcon('assets/images/header-pin.svg');
  }

  &-contactTitle
  {
    @include Heading--subWithIcon('assets/images/cell_phone.png');
  }

  &-calculator
  {
    @include Heading--subWithIcon('assets/images/home.svg');
  }

  &-calculatorLink
  {
    @include Link--headerSecondary;

    @include bplte(xs)
    {
      display: flex;
    }

    &:hover
    {
      text-decoration: underline;
    }
  }

  &-events
  {
    margin-top: 32px;
    background-color: $brand-lightgreen;
    color: white;

    &:hover
    {
      background-color: $brand-primary;
    }

    @include bplte(xs)
    {
      margin-top: 24px;
    }
  }

  &-rightInner
  {
    @include bplte(xs)
    {
      background-color: $brand-lightblue;
      padding: $spacing-MLO*2;
    }
  }

  &-emailLink
  {
    display: flex;
  }

  &-addressWrapper
  {
    margin-bottom: $spacing-MLO*3;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-MLO*4;
    }
  }

  &-phoneWrapper
  {
    display: flex;
    margin-bottom: $spacing-MLO;
  }

  &-contactLabel
  {
    font-weight: $font-weight-bold;
    margin-right: $spacing-MLO/2;
  }

  &-noImage
  {
    display: flex;

    @include bplte(xs)
    {
      flex-wrap: wrap;
    }

    .Nap-actNow
    {
      @include bpgte(sm)
      {
        margin-right: 40px;
        margin-bottom: 0;
      }
    }
  }

  &-ctaContainer
  {
    position: relative;
    z-index: 2;
  }

  /* postcss-bem-linter: ignore */
  .c-phone-label
  {
    display: none;
  }

  /* postcss-bem-linter: ignore */
  .c-phone-number-link
  {
    @include Link--body;
    font-weight: $font-weight-bold;
  }

  /* postcss-bem-linter: ignore */
  .c-AddressRow:last-child
  {
    display: none;
  }

  /* postcss-bem-linter: ignore */
  .c-get-directions
  {
    margin-top: $spacing-MLO;
  }

  /* postcss-bem-linter: ignore */
  .c-get-directions-button
  {
    @include Link--headerSecondary;

    @include bplte(xs)
    {
      display: flex;
    }
  }

  &-infoWrapper
  {
    background-color: $brand-lightgreen;
    position: relative;
    padding-right: $spacing-MLO*2;

    @include bpgte(sm)
    {
      flex-grow: 1;
      padding-top: $spacing-MLO*4;
      padding-bottom: $spacing-MLO*4;
      padding-left: $spacing-MLO*4;
    }

    @include bplte(xs)
    {
      padding: $spacing-MLO*2;
    }

    /* postcss-bem-linter: ignore */
    .c-phone-number-link
    {
      color: white;
    }
  }
}
