/** @define About */
$spacing-about: 20px !default;

.About
{
  @include l-container;
  background-color: $brand-lightblue;
  margin-top: $spacing-about;
  padding: $spacing-about;

  @include bpgte(sm)
  {
    margin-top: $spacing-about*2;
    padding: $spacing-MLO*4;
    display: flex;
  }

  @include bplte(sm)
  {
    flex-wrap: wrap;
  }

  &-titleMain
  {
    color: $brand-lightgreen;
    margin-bottom: $spacing-about;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-about*2;
    }
  }

  &-advisorInfo
  {
    @include l-col(7, 12);
    background-color: white;
    padding: $spacing-MLO*2;

    @include bpgte(sm)
    {
      padding-top: $spacing-MLO*4;
      padding-bottom: $spacing-MLO*4;
      padding-left: $spacing-about*2;
      padding-right: $spacing-about*2;
    }

    @include bplte(sm)
    {
      width: 100%;
      padding-left: 12px;
    }
  }

  &-branchInfo
  {
    @include l-col(5, 12);
    padding: $spacing-MLO*2;

    @include bpgte(sm)
    {
      padding-right: $spacing-about*2;
      padding-bottom: $spacing-about*2;
      padding-left: 22px;
      padding-top: $spacing-MLO*4;
    }

    @include bplte(sm)
    {
      width: 100%;
    }
  }

  &-subTitle
  {
    @include Heading--subWithIcon('assets/images/officer.svg');
  }

  &-list
  {
    margin-left: $spacing-MLO*2;
  }

  &-organizationsTitle
  {
    @include Heading--subWithIcon('assets/images/organization.svg');
    margin-bottom: $spacing-MLO*2;
  }

  &-awardTitle
  {
    @include Heading--subWithIcon('assets/images/star.svg');
    background-repeat: no-repeat;
    margin-bottom: $spacing-MLO*2;
    margin-left: 3px;

    &::before
    {
      position: relative;
      top: 4px;
      background-repeat: no-repeat;
    }
  }

  &-productsTitle
  {
    @include Heading--subWithIcon('assets/images/sign.svg');
    margin-bottom: $spacing-MLO*2;
  }

  &-educationTitle
  {
    @include Heading--subWithIcon('assets/images/education.svg');
    margin-bottom: $spacing-MLO*2;
  }

  &-educationWrapper
  {
    flex: 1;

    @include bplte(xs)
    {
      margin-top: $spacing-MLO*2;
    }
  }

  &-branchTitle
  {
    @include Heading--subWithIcon('assets/images/branch.svg');
    margin-bottom: $spacing-MLO*2;
  }

  &-listItem
  {
    list-style-type: disc;
    font-size: 16px;

    &:not(:last-child)
    {
      margin-bottom: $spacing-MLO;
    }
  }

  &-listItemText,
  &-listItemLink
  {
    font-family: $font-family-main;
  }

  &-listItemLink
  {
    color: $brand-link;
    text-decoration: underline;

    &:hover
    {
      text-decoration: none;
    }
  }

  &-awardWrapper
  {
    flex: 1;

    @include bpgte(sm)
    {
      margin-right: 32px;
    }
  }

  &-organizationsText
  {
    font-family: $font-family-secondary;
    margin-bottom: $spacing-MLO*2;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-MLO*4;
    }
  }

  &-aboutMeText
  {
    font-family: $font-family-main;
    margin-top: $spacing-MLO*2;
    margin-bottom: $spacing-MLO*2;

    @include bpgte(sm)
    {
      margin-bottom: $spacing-MLO*4;
    }
  }

  &-certsWrapper
  {
    @include bpgte(sm)
    {
      display: flex;
    }
  }

  &-socialTitle
  {
    @include Heading--subWithIcon('assets/images/connect_with_me.png');
    margin-bottom: 16px;
    font-family: $font-family-main;
  }

  &-socialIcon
  {
    width: 32px;
    height: 32px;
    margin-right: $spacing-MLO*3;

    @include bpgte(sm)
    {
      margin-right: $spacing-MLO*2;
    }
  }

  &-socialWrapper
  {
    margin-top: $spacing-MLO*2;

    @include bpgte(sm)
    {
      margin-top: $spacing-about*2;
    }
  }

  &-button
  {
    width: 189px;
    margin-top: $spacing-about;

    @include bpgte(sm)
    {
      margin-top: 36px;
    }
  }
}
