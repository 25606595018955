.Events
{
  @include l-container;

  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $brand-lightblue;
  padding: $spacing-MLO*2;
  display: flex;
  flex-direction: column;

  @include bpgte(sm)
  {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: $spacing-MLO*4;
  }

  &-title
  {
    @include Heading--minor;

    color: $brand-lightgreen;
    margin-bottom: 32px;

    @include bplte(sm)
    {
      margin-bottom: 20px;
    }
  }

  .c-events-title
  {
    display: none;
  }

  .c-events-event
  {
    &:not(:last-child)
    {
      margin-bottom: 40px;

      @include bplte(xs)
      {
        margin-bottom: 16px;
      }
    }
  }

  .c-events-event-details
  {
    display: flex;

    @include bplte(sm)
    {
      flex-direction: column;
    }

    &-left
    {
      flex: 1 0 50%;

      @include bplte(sm)
      {
        flex: 1 0 100%;
      }
    }

    &-right
    {
      width: 100%;
      background: white;
      padding: 32px;

      @include bplte(sm)
      {
        padding: 16px;
      }
    }

    &-image-wrapper
    {
      position: relative;
      overflow: hidden;
      height: 100%;
    }

    &-image
    {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
      position: absolute;

      @include bplte(sm)
      {
        position: relative;
      }
    }

    &-title
    {
      @include font-sizes(18px, 24px);

      margin-bottom: 16px;
      color: $brand-lightgreen;
    }

    &-title-link
    {
      @include Link;
      @include font-sizes(18px, 24px);

      color: $brand-lightgreen;
    }

    &-date,
    &-time
    {
      @include font-sizes(18px, 24px);

      color: $brand-lightgreen;
      height: 24px;

      &,
      &-icon
      {
        margin-bottom: 8px;
      }

      &-icon
      {
        vertical-align: middle;
        margin-right: 8px;
      }
    }

    &-description
    {
      font-family: $font-family-secondary;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &-link
    {
      display: inline-flex;
    }
  }
}
