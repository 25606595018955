/** @define Promo */
.Promo
{
  @include l-container;
  position: relative;
  background-size: cover;

  &-overlay
  {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
  }

  @include bpgte(sm)
  {
    margin-top: 40px;
  }

  &-background
  {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
  }

  &-wrapper
  {
    z-index: 2;
    position: relative;
    padding-top: $spacing-MLO*4;
    padding-bottom: $spacing-MLO*4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @include bpgte(sm)
    {
      padding-top: $spacing-MLO*8;
      padding-bottom: $spacing-MLO*8;
    }

    @include bplte(xs)
    {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-title
  {
    margin-bottom: 20px;

    @include bpgte(sm)
    {
      margin-bottom: 40px;
      width: 100%;
      text-align: center;
    }
  }

  &-text
  {
    color: white;
    font-family: $font-family-main;
    text-align: center;

    @include bpgte(sm)
    {
      width: 553px;
    }
  }

  &-buttonWrapper
  {
    width: 100%;
    margin-top: $spacing-MLO*2;
    display: flex;
    justify-content: space-around;

    @include bpgte(sm)
    {
      margin-top: $spacing-MLO*4;
    }
  }
}
