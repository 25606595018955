/** @define Calculator */
.Calculator
{
  @include l-container;

  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $brand-lightblue;
  padding: $spacing-MLO*2;
  display: flex;
  flex-direction: column;

  @include bpgte(sm)
  {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: $spacing-MLO*4;
  }

  &-title
  {
    color: $brand-lightgreen;
    margin-bottom: 16px;
  }

  &-text
  {
    margin-bottom: $calculator-padding;
    font-family: $font-family-main;
    font-size: 16px;
  }

  &-toggleWrapper
  {
    display: flex;
  }

  &-leftToggle,
  &-rightToggle
  {
    border-radius: 15px 15px 0 0;
    font-size: 24px;
    text-align: center;
    flex: 1;
    padding-top: $calculator-padding;
    padding-bottom: $calculator-padding;
    padding-left: 10px;
    padding-right: 10px;
    color: $gray-1;
    background-color: $gray-6;

    &:hover
    {
      cursor: pointer;
    }

    /* postcss-bem-linter: ignore */
    &.is-clicked
    {
      color: $brand-white;
      background-color: $brand-lightgreen;
    }
  }

  &-leftFormWrapper,
  &-rightFormWrapper
  {
    border: $border-calculator;
    background-color: $brand-white;
    color: $gray-1;
    padding: $calculator-padding;
    display: flex;
    flex-direction: row;

    &.is-hidden
    {
      display: none;
    }

    @include bplte(xs)
    {
      flex-direction: column;
    }
  }

  &-leftWrapper,
  &-rightWrapper
  {
    width: 50%;
  }

  &-leftWrapper
  {
    padding-right: $calculator-padding;

    @include bplte(sm)
    {
      width: 60%;
    }

    @include bplte(xs)
    {
      padding-right: 0;
      width: 100%;
    }
  }

  &-rightWrapper
  {
    border: $border-calculator;
    padding: 30px;

    &.is-hidden
    {
      display: none;
    }

    @include bplte(sm)
    {
      width: 40%;
    }

    @include bplte(xs)
    {
      width: 100%;
    }
  }

  &-fieldTop,
  &-bottomWrapper
  {
    margin-bottom: $form-padding;
  }

  &-bottomWrapper
  {
    display: flex;
  }

  &-fieldText
  {
    margin-bottom: $form-padding/2;
  }

  &-fieldBottomLeft,
  &-fieldBottomRight
  {
    flex: 1;
  }

  &-fieldBottomLeft
  {
    margin-right: $form-padding;
  }

  &-fieldBottomRight
  {
    margin-left: $form-padding;
  }

  &-textbox
  {
    border: $border-textbox;
    border-radius: 4px;
    width: 100%;
    height: 42px;
    padding-left: $form-padding;
    padding-right: $form-padding;
  }

  &-button
  {
    width: 50%;
    margin-top: $calculator-padding;

    &:hover
    {
      cursor: pointer;
    }

    @include bplte(xs)
    {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &-amountText
  {
    color: $brand-lightgreen;
    font-size: 30px;
    font-weight: $font-weight-bold;
    margin-bottom: $form-padding/2;
  }

  &-errorText
  {
    color: $brand-darkorange;
    display: none;

    @include bpgte(sm)
    {
      margin-top: $form-padding;
    }
  }

  &-disclaimer
  {
    margin-top: $calculator-padding;

    @include bplte(xs)
    {
      margin-top: $form-padding;
    }
  }
}
