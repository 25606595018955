/** @define Articles */

.Articles
{
  &-wrapper
  {
    padding-top: 80px;

    @include bplte(xs)
    {
      padding: 40px 16px;
    }
  }

  &-list
  {
    &--center
    {
      justify-content: center;
    }
  }

  &-title
  {
    color: $brand-lightgreen;
    margin-bottom: 36px;

    @include bplte(xs)
    {
      margin-bottom: 40px;
    }
  }

  &-item
  {
    margin-bottom: 40px;
    font-family: $font-family-secondary;
    display: flex;
    flex-direction: column;
  }

  &-itemTitle
  {
    color: $brand-link;
    margin-bottom: 26px;

    &:hover
    {
      text-decoration: underline;
    }
  }

  &-image
  {
    height: 240px;
    width: 100%;
  }

  &-itemCta
  {
    @include font-sizes(16px, 22px);

    @include bplte(xs)
    {
      display: flex;
    }
  }

  &-itemDescription
  {
    flex-grow: 1;
  }

  &-itemDate,
  &-itemDescription
  {
    font-family: $font-family-main;
  }

  &-itemDate,
  &-itemDescription,
  &-imageWrapper
  {
    margin-bottom: 16px;
  }

  &-buttons
  {
    display: flex;
    justify-content: center;
  }

  &-toggle
  {
    display: flex;
    align-items: center;
    font-family: $font-family-secondary;
    text-decoration: none;

    &:hover
    {
      text-decoration: underline;
    }
  }

  &-toggleIcon
  {
    width: 18px;
    height: 18px;
    margin-left: 8px;

    &--minus
    {
      height: 2px;
    }
  }

  //postcss-bem-linter: ignore
  .is-hidden
  {
    display: none;
  }
}
