/** @define LocationCard */

.LocationCard
{
  @include l-col(3, 12);
  background-color: $brand-lightblue;
  padding-top: $spacing-MLO*2;
  padding-bottom: $spacing-MLO*2;

  @include bpgte(sm)
  {
    max-width: 260px;
  }

  @include bplte(sm)
  {
    margin-bottom: 20px;
  }

  @include bplte(xs)
  {
    width: 100%;
  }

  &:not(:last-child)
  {
    @include bpgte(sm)
    {
      margin-right: 40px;
    }
  }

  &--directory
  {
    @include bplte(xs)
    {
      margin-bottom: 0;
    }
  }

  &-detail
  {
    flex-grow: 1;
  }

  &-title
  {
    @include Link--headerSecondary;
    margin-bottom: $spacing-MLO;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include bplte(xs)
    {
      display: flex;
      justify-content: flex-start;
    }
  }

  &-nmls
  {
    margin-bottom: $spacing-MLO;
    font-weight: $font-weight-bold;
  }

  &-headshotWrapper
  {
    display: flex;
    align-items: center;
  }

  &-imageWrapper
  {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &-image
  {
    width: 64px;
    min-width: 50px;
    height: 64px;
    border-radius: 32px;
    border: $border-black;
  }

  /* postcss-bem-linter: ignore */
  .c-location-hours-today
  {
    @include Text;
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-MLO;
  }

  /* postcss-bem-linter: ignore */
  .c-AddressRow:last-child
  {
    display: none;
  }
}
